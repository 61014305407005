import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { Switch, SwitchCheckbox, SwitchSlider } from 'styles/CommonStyles';
import CommonPopover from 'components/popovers/CommonPopover';
import { MaterialIcon } from 'styles/Icons';
import { noOp } from 'lib/utils';

const AutoPayRoot = styled.div`
  font-family: ${(props) => props.theme.font.fontFamily.default};
`;

const AutoPayIndicator = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    flex-flow: row nowrap;
  }
`;

const AutoPayText = styled.div`
  color: ${(props) => (props.checked ? props.theme.colors.hyperlinkBlue : props.theme.colors.headerGrey)};
  margin-bottom: 0;
  font-weight: lighter;

  @media screen and (max-width: ${(props) => props.theme.minWidth.pico}) {
    font-size: 0.8rem;
  }
`;

const AutoPayEnabled = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row;
`;

const SliderTextWrap = styled.div`
  color: white;
  font-size: 0.8em;
  height: 100%;
  display: flex;
  float: right;
`;
const SliderText = styled.span`
  margin: auto 6px;
`;

const iconSize = '1.2rem';
const iconStyle = {
  marginRight: '0.5rem',
  marginTop: '2px',
};

const AutoPay = ({ checked, className, openAutoPayModal, onEnableAutoPay, disabled }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <AutoPayRoot className={className} data-testid="autopay">
        {checked ? (
          <AutoPayEnabled data-testid="autopay-button" onClick={openAutoPayModal}>
            <MaterialIcon name="info" size={iconSize} color={themeContext.colors.hyperlinkBlue} style={iconStyle} />
            <AutoPayText checked={checked}>{t('account.payment_methods.autopay.autopay_enabled')}</AutoPayText>
          </AutoPayEnabled>
        ) : (
          <>
            <AutoPayIndicator>
              <CommonPopover
                type="autopay"
                placement="top"
                color={themeContext.colors.headerGrey}
                icon="info"
                textcontent={t('account.payment_methods.autopay.autopay_tooltip')}
              />
              <AutoPayText checked={checked} className="ml-1">
                {t('account.payment_methods.autopay.enable_autopay')}
              </AutoPayText>
            </AutoPayIndicator>
            <Switch data-testid="autopay-button">
              <SwitchCheckbox disabled={disabled} onClick={onEnableAutoPay} type="checkbox" value="on" />
              <SwitchSlider>
                <SliderTextWrap>
                  <SliderText>OFF</SliderText>
                </SliderTextWrap>
              </SwitchSlider>
            </Switch>
          </>
        )}
      </AutoPayRoot>
    </>
  );
};

AutoPay.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onEnableAutoPay: PropTypes.func,
  openAutoPayModal: PropTypes.func,
};

AutoPay.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  onEnableAutoPay: noOp,
  openAutoPayModal: noOp,
};

export default AutoPay;
