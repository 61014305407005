import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatExpiryDate, formatMaskedCreditCardNumber } from 'lib/Formatters';
import { noOp } from 'lib/utils';

// State
import { paymentMethodsLoadingSelector } from 'state/paymentMethods/selectors';

// Components
import AutoPay from 'views/account/wallet/components/AutoPay';
import Loader from 'components/Loader';
import Menu from 'views/account/wallet/components/Menu';

// Styles
import { CC, IconColor, MaskedCC } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

import {
  MiniPanel,
  CCInfo,
  CCHeader,
  Error,
  HiddenDiv,
  iconColor,
  iconSize,
  iconStyle,
  StatusRow,
} from 'styles/PaymentMethodStyles';
import { enableLoanAutoPay } from 'state/autoPay/actions';

import PaymentMethodLoans from 'views/account/wallet/components/PaymentMethodLoans';
import { closedLoansStatuses } from 'lib/constants/loanStatuses';

const MiniPanel2 = styled(MiniPanel)`
  background-color: ${(props) => props.theme.colors.upliftWhite};
  margin: 1.5rem auto;
`;

const Separator = styled.hr`
  color: ${(props) => props.theme.colors.borderGrey};
  margin-bottom: 24px;
  margin-top: 24px;

  // Designed to match padding from MiniPanel styling so separator extends full way across
  position: relative;
  left: -1.5rem;
  width: calc(100% + 3rem);
`;

const ExpiryContainer = styled.div`
  visibility: ${(props) => (props.visibility ? props.visibility : 'visible')};
  margin: 0 auto;
`;

const MenuSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAutoPay = styled(AutoPay)`
  align-self: flex-start;
`;

const PaymentMethod = memo((props) => {
  const {
    compact,
    hideAutoPay,
    hideMenu,
    loan, // Needed for AutoPay
    method,
    onAutoPayEnabledClick,
    onEnableAutoPay,
    dismissAllAlerts,
    setShowAutoPayAlertFail,
    setShowAutoPayAlertSuccess,
    setShowRemoveAccountAlertFail,
    setShowRemoveAccountAlertSuccess,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const id = method.paymentMethodId;

  const isAch = method.paymentMethodType === 'ach';
  const header = isAch ? 'bank_account' : 'card';
  const type = isAch ? 'ach' : 'cc';
  const icon = isAch ? 'account_balance' : 'credit_card';
  const accountNumber = isAch ? method.accountNumberMask : method.cardNumberMask;
  const panelPadding = compact ? '1.5rem 1rem' : undefined;

  const paymentMethodsLoading = useSelector(paymentMethodsLoadingSelector);

  const loanId = loan?.loanId;
  const isLoanPaidOff = loan?.loanStatusCode && closedLoansStatuses.includes(loan.loanStatusCode.toUpperCase());
  const autoPaySuccess = useCallback(() => {
    setShowAutoPayAlertSuccess(true);
    dispatch(enableLoanAutoPay(loanId));
  }, [dispatch, loanId, setShowAutoPayAlertSuccess]);

  const autoPayError = useCallback(() => {
    setShowAutoPayAlertFail(true);
  }, [setShowAutoPayAlertFail]);

  const loading = paymentMethodsLoading;

  if (loading) {
    return (
      <>
        <MiniPanel showCard padding="3.5rem 1.5rem" default={method.defaultPaymentMethod}>
          <Loader />
        </MiniPanel>
      </>
    );
  }

  return (
    <>
      <MiniPanel2 key={id} default={method.defaultPaymentMethod} padding={panelPadding} showCard>
        <CCInfo>
          <div>
            <CCHeader data-testid={`${type}-header`}>{t(`account.payment_methods.details.${header}`)}</CCHeader>
            <CC>
              <MaterialIcon name={icon} color={IconColor} size="32px" style={{ marginRight: '14px' }} />
              <MaskedCC data-testid={`${type}-end-in`}>
                <HiddenDiv as="span">{t('account.payment_methods.details.end_in')}</HiddenDiv>
                <b>{formatMaskedCreditCardNumber(accountNumber)}</b>
              </MaskedCC>
            </CC>
          </div>

          <ExpiryContainer data-testid="cc-expires" visibility={isAch ? 'hidden' : null}>
            <CCHeader>{t('account.payment_methods.details.expires')}</CCHeader>
            <div>{formatExpiryDate(method.cardMonth, method.cardYear)}</div>
          </ExpiryContainer>

          {!hideAutoPay && (
            <StyledAutoPay
              checked={loan?.autopay}
              loanId={loan?.loanId}
              disabled={loading || isLoanPaidOff}
              merchantName={loan?.merchantInfo?.name}
              onAutoPayError={autoPayError}
              onAutoPaySuccess={autoPaySuccess}
              onEnableAutoPay={onEnableAutoPay}
              openAutoPayModal={onAutoPayEnabledClick}
              paymentMethodId={method.paymentMethodId}
            />
          )}

          {!hideMenu && (
            <MenuSection>
              <StatusRow>
                <Error data-testid="invalid-payment-method" style={{ display: method?.valid ? 'none' : null }}>
                  <MaterialIcon name="report" size={iconSize} color={iconColor} style={iconStyle} />
                  <div>{t('account.payment_methods.details.invalid')}</div>
                </Error>
              </StatusRow>

              <Menu
                method={method}
                dismissAllAlerts={dismissAllAlerts}
                setShowRemoveAccountAlertFail={setShowRemoveAccountAlertFail}
                setShowRemoveAccountAlertSuccess={setShowRemoveAccountAlertSuccess}
              />
            </MenuSection>
          )}
        </CCInfo>
        {method?.merchantLoansMap && Object.keys(method.merchantLoansMap).length ? (
          <>
            <Separator />
            <PaymentMethodLoans merchantLoansMap={method.merchantLoansMap} />
          </>
        ) : null}
      </MiniPanel2>
    </>
  );
});

PaymentMethod.displayName = 'PaymentMethod';

PaymentMethod.propTypes = {
  compact: PropTypes.bool,
  hideAutoPay: PropTypes.bool,
  hideMenu: PropTypes.bool,
  loan: PropTypes.shape({
    autopay: PropTypes.bool,
    loanId: PropTypes.string,
    loanStatusCode: PropTypes.string,
    merchantInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  method: PropTypes.shape({
    accountNumberMask: PropTypes.string,
    cardMonth: PropTypes.string,
    cardNumberMask: PropTypes.string,
    cardYear: PropTypes.string,
    defaultPaymentMethod: PropTypes.bool.isRequired,
    paymentMethodId: PropTypes.string.isRequired,
    paymentMethodType: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    merchantLoansMap: PropTypes.objectOf(PropTypes.array),
  }).isRequired,
  onAutoPayEnabledClick: PropTypes.func,
  onEnableAutoPay: PropTypes.func,
  dismissAllAlerts: PropTypes.func,
  setShowAutoPayAlertFail: PropTypes.func,
  setShowAutoPayAlertSuccess: PropTypes.func,
  setShowRemoveAccountAlertFail: PropTypes.func,
  setShowRemoveAccountAlertSuccess: PropTypes.func,
};

PaymentMethod.defaultProps = {
  compact: false,
  hideAutoPay: false,
  hideMenu: false,
  loan: null,
  onAutoPayEnabledClick: noOp,
  onEnableAutoPay: noOp,
  dismissAllAlerts: null,
  setShowAutoPayAlertFail: noOp,
  setShowAutoPayAlertSuccess: noOp,
  setShowRemoveAccountAlertFail: null,
  setShowRemoveAccountAlertSuccess: null,
};

export default PaymentMethod;
