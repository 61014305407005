import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import history from 'History.js'; // eslint-disable-line import/extensions
import { PLAID } from 'lib/constants/alertTypes';
import { addLoanAutoPayDocument, enableLoanAutoPay, setShowAutoPayFail, setShowAutoPaySuccess } from 'state/autoPay/actions';
import {
  autoPayAlertLoanIdSelector,
  autoPayInitializeBatchFailuresSelector,
  autoPayInitializeSelector,
  showAutoPayFailSelector,
  showAutoPaySuccessSelector,
} from 'state/autoPay/selectors';
import { switchPaymentMethodForLoan } from 'state/loans/setPaymentMethod/actions';
import { defaultPaymentMethodSelector, paymentMethodsSelector } from 'state/paymentMethods/selectors';
import { paymentMethodEdit } from 'state/secureFormsPaymentMethods/actions';
import {
  showPaymentMethodAssociationFailure,
  showPaymentMethodAssociationSuccess,
  showPaymentMethodFail,
  showPaymentMethodSuccess,
} from 'state/updatePaymentMethods/actions';
import { MaterialIcon } from 'styles/Icons';
import { STEPS } from 'views/account/paymentMethods/PaymentMethodAssociationFlow';
import PaymentMethodAssociationFlowModal from 'views/account/paymentMethods/PaymentMethodAssociationFlowModal';
import AddPaymentMethodButton from 'views/account/wallet/components/AddPaymentMethodButton';
import StyledAlert from 'views/account/wallet/components/Alert';
import AutoPayEnabledModal from 'views/account/wallet/components/AutoPayEnabledModal';
import AutoPayFlow from 'views/account/wallet/components/AutoPayFlow';
import BaseWallet from 'views/account/wallet/components/BaseWallet';
import EmptyWallet from 'views/account/wallet/components/EmptyWallet';
import PaymentMethod from 'views/account/wallet/components/PaymentMethod';
import { setPaymentMethodPrefillLoans } from 'state/paymentMethods/actions';

const PaymentMethodBody = styled.div`
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    margin: 0.5rem 3rem 0 4.5rem;
  }
`;

const StyledButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledAlertBody = styled.div`
  display: flex;

  i {
    margin-right: 1rem;
  }
`;

const LoanPaymentMethod = ({ loan }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const paymentMethods = useSelector(paymentMethodsSelector);
  const autoPayInitializeData = useSelector(autoPayInitializeSelector);
  const showAutoPayFail = useSelector(showAutoPayFailSelector);
  const showAutoPaySuccess = useSelector(showAutoPaySuccessSelector);
  const autoPayAlertLoanId = useSelector(autoPayAlertLoanIdSelector);
  const defaultPaymentMethod = useSelector(defaultPaymentMethodSelector);
  const autopayInitializeBatchFailures = useSelector(autoPayInitializeBatchFailuresSelector);

  const autopayInitializeBatchFailuresRef = useRef();

  const [isChangingMethod, setIsChangingMethod] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAutoPayEnabled, setShowAutoPayEnabled] = useState(false);
  const [showAutoPayFlow, setShowAutoPayFlow] = useState(false);
  const [targetPaymentMethodId, setTargetPaymentMethod] = useState(null);
  const [showPaymentAssociationModal, setShowPaymentAssociationModal] = useState(false);

  const { loanId, paymentToken } = loan;
  const paymentMethod =
    paymentMethods && paymentMethods.length && paymentToken
      ? paymentMethods.find((method) => method.paymentMethodId === paymentToken)
      : null;

  const dismissAllAlerts = useCallback(() => {
    dispatch(showPaymentMethodSuccess(false));
    dispatch(showPaymentMethodFail(false));
    dispatch(setShowAutoPaySuccess(undefined, false));
    dispatch(setShowAutoPayFail(undefined, false));
    setShowFail(false);
    setShowSuccess(false);
  }, [dispatch]);

  const handleSuccess = useCallback((isShow) => {
    setShowSuccess(isShow);
  }, []);

  const handleFail = useCallback(
    (isShow, type) => {
      if (type === PLAID) {
        dispatch(showPaymentMethodFail(isShow));
        if (isShow) {
          history.push('/account');
        }
      } else {
        setShowFail(isShow);
      }
    },
    [dispatch]
  );

  // Set the value in the ref so it can be referenced in callbacks
  autopayInitializeBatchFailuresRef.current = autopayInitializeBatchFailures;

  const handleAssociationsComplete = (successes, failures) => {
    if (successes?.length) {
      dispatch(showPaymentMethodAssociationSuccess(true));
    }

    if (failures?.length || autopayInitializeBatchFailuresRef.current?.length) {
      dispatch(showPaymentMethodAssociationFailure(true));
      history.push('/account');
    }
  };

  const addNewCardMethod = useCallback(
    (event, id) => {
      dispatch(paymentMethodEdit(id));
      history.push('/account');
    },
    [dispatch]
  );

  const hideAutoPayEnabledModal = useCallback(() => {
    setShowAutoPayEnabled(false);
  }, []);

  const hideAutoPayFlow = useCallback(() => {
    setShowAutoPayFlow(false);
  }, []);

  const hideAutoPayFailAlert = useCallback(() => {
    dispatch(setShowAutoPayFail(null, false));
  }, [dispatch]);

  const hideAutoPaySuccessAlert = useCallback(() => {
    dispatch(setShowAutoPaySuccess(null, false));
  }, [dispatch]);

  const setPaymentMethod = useCallback((method) => {
    setTargetPaymentMethod(method.paymentMethodId);
    setIsChangingMethod(true);
  }, []);

  const showAutoPayEnabledModal = useCallback(() => {
    setShowAutoPayEnabled(true);
  }, []);

  const showAutoPayError = useCallback(() => {
    setShowAutoPayFlow(false);
    dispatch(setShowAutoPayFail(loanId, true));
  }, [dispatch, loanId]);

  const startAutoPayFlow = useCallback(() => {
    setTargetPaymentMethod(paymentMethod.paymentMethodId);
    setIsChangingMethod(false);
    setShowAutoPayFlow(true);
  }, [paymentMethod]);

  const updateAutoPay = useCallback(() => {
    if (!isChangingMethod) {
      dispatch(setShowAutoPaySuccess(loanId, true));
      setShowSuccess(false);
    } else {
      setShowSuccess(true);
      dispatch(setShowAutoPaySuccess(null, false));
    }
    setShowAutoPayFlow(false);

    const { message, sessionId, ...newDocument } = autoPayInitializeData;
    dispatch(addLoanAutoPayDocument(loanId, newDocument));
    dispatch(switchPaymentMethodForLoan(loanId, targetPaymentMethodId));
    dispatch(enableLoanAutoPay(loanId));
  }, [autoPayInitializeData, dispatch, isChangingMethod, loanId, targetPaymentMethodId]);

  const updatePaymentMethod = useCallback(
    (newPaymentToken) => {
      dispatch(switchPaymentMethodForLoan(loanId, newPaymentToken));
      setShowSuccess(true);
    },
    [dispatch, loanId]
  );

  return (
    <PaymentMethodBody>
      {showFail && (
        <StyledAlert setShowAlert={setShowFail} showAlert={showFail} variant="danger">
          <StyledAlertBody>
            <MaterialIcon name="error_outline" size="1.5rem" color="#EE0606" />
            {t('loans.payment.payment_method_fail')}
          </StyledAlertBody>
        </StyledAlert>
      )}
      {showAutoPayFail && autoPayAlertLoanId === loanId && (
        <StyledAlert setShowAlert={hideAutoPayFailAlert} showAlert={showAutoPayFail} variant="danger">
          <StyledAlertBody>
            <MaterialIcon name="error_outline" size="1.5rem" color="#EE0606" />
            {t('loans.payment.enable_auto_pay_fail')}
          </StyledAlertBody>
        </StyledAlert>
      )}
      {showSuccess && paymentMethod && (
        <StyledAlert setShowAlert={setShowSuccess} showAlert={showSuccess} variant="success">
          <StyledAlertBody>
            <MaterialIcon name="check_circle_outline" size="1.5rem" color="#17AD4E" />
            {t(loan?.autopay ? 'loans.payment.payment_method_success_with_auto_pay' : 'loans.payment.payment_method_success', {
              paymentType: paymentMethod?.paymentMethodType === 'ach' ? 'Bank Account' : 'Card',
              paymentNumber:
                paymentMethod?.paymentMethodType === 'ach'
                  ? paymentMethod?.accountNumberMask?.slice(-4)
                  : paymentMethod?.cardNumberMask?.slice(-4),
              loanId,
            })}
          </StyledAlertBody>
        </StyledAlert>
      )}
      {showAutoPaySuccess && autoPayAlertLoanId === loanId && (
        <StyledAlert setShowAlert={hideAutoPaySuccessAlert} showAlert={showAutoPaySuccess} variant="success">
          <StyledAlertBody>
            <MaterialIcon name="check_circle_outline" size="1.5rem" color="#17AD4E" />
            {t('loans.payment.enable_auto_pay_success')}
          </StyledAlertBody>
        </StyledAlert>
      )}
      {!paymentMethod && !paymentMethods?.length && (
        <EmptyWallet
          addCardPayment={addNewCardMethod}
          dismissAllAlerts={dismissAllAlerts}
          setShowAlertFail={handleFail}
          setShowAlertSuccess={handleSuccess}
          loan={loan}
          setShowPaymentAssociationModal={setShowPaymentAssociationModal}
        />
      )}
      {!paymentMethod && !!paymentMethods?.length && (
        <BaseWallet
          button={
            <AddPaymentMethodButton
              addCardPayment={addNewCardMethod}
              buttonText={t('account.payment_methods.modal.add_payment_method')}
              dismissAllAlerts={dismissAllAlerts}
              hideButtonIcon
              loan={loan}
              paymentMethods={paymentMethods}
              selectPaymentMethod={setPaymentMethod}
              setShowAlertFail={handleFail}
              setShowAlertSuccess={handleSuccess}
              setShowAutoPay={setShowAutoPayFlow}
              updatePaymentMethod={updatePaymentMethod}
              setShowPaymentAssociationModal={setShowPaymentAssociationModal}
            />
          }
          title={t('loans.payment.missing_payment_method')}
        />
      )}
      {paymentMethod && (
        <>
          <PaymentMethod
            hideMenu
            onAutoPayEnabledClick={showAutoPayEnabledModal}
            onEnableAutoPay={startAutoPayFlow}
            loan={loan}
            method={{ ...paymentMethod, defaultPaymentMethod: false }}
          />
          <StyledButtonBar>
            <AddPaymentMethodButton
              addCardPayment={addNewCardMethod}
              buttonText={t('loans.payment.change_payment_method')}
              dismissAllAlerts={dismissAllAlerts}
              hideButtonIcon
              loan={loan}
              paymentMethods={paymentMethods.filter((method) => method.paymentMethodId !== paymentMethod.paymentMethodId)}
              selectPaymentMethod={setPaymentMethod}
              setShowAlertFail={handleFail}
              setShowAlertSuccess={handleSuccess}
              setShowAutoPay={setShowAutoPayFlow}
              updatePaymentMethod={updatePaymentMethod}
              setShowPaymentAssociationModal={setShowPaymentAssociationModal}
            />
          </StyledButtonBar>
        </>
      )}
      {showAutoPayFlow && (
        <AutoPayFlow
          loanId={loan?.loanId}
          loanStatusCode={loan?.loanStatusCode}
          merchantInfo={loan?.merchantInfo}
          onAutoPayClose={hideAutoPayFlow}
          onAutoPayAuthorizeClose={hideAutoPayFlow}
          onAutoPayAuthorizeConfirm={updateAutoPay}
          onError={showAutoPayError}
          paymentMethodId={targetPaymentMethodId}
          skipToAuthorize={loan && loan.paymentToken && loan.autopay}
        />
      )}
      {showAutoPayEnabled && (
        <AutoPayEnabledModal loanId={loan.loanId} onClose={hideAutoPayEnabledModal} onViewDocumentClick={hideAutoPayEnabledModal} />
      )}
      {showPaymentAssociationModal && (
        <PaymentMethodAssociationFlowModal
          paymentMethod={defaultPaymentMethod}
          onComplete={handleAssociationsComplete}
          onClose={() => {
            setShowPaymentAssociationModal(false);
            dispatch(setPaymentMethodPrefillLoans([]));
          }}
          onError={(successes, failures, selection, step) => {
            // If all selections are autopay enabled and all of the autopay initializations fail, treat this as the end of the flow.
            if (step === STEPS.LOAN_SELECTION && selection?.length === failures?.length) {
              setShowPaymentAssociationModal(false);
              dispatch(showPaymentMethodAssociationFailure(!!failures?.length));
              dispatch(setPaymentMethodPrefillLoans([]));
            }
          }}
        />
      )}
    </PaymentMethodBody>
  );
};

LoanPaymentMethod.propTypes = {
  loan: PropTypes.shape({
    autopay: PropTypes.bool,
    loanId: PropTypes.string,
    merchantInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
    paymentToken: PropTypes.string,
    loanStatusCode: PropTypes.oneOf([null, PropTypes.string]),
  }).isRequired,
  paymentMethod: PropTypes.shape({
    accountNumberMask: PropTypes.string,
    cardNumberMask: PropTypes.string,
    paymentMethodId: PropTypes.string,
    paymentMethodType: PropTypes.oneOf(['ach', 'card']),
  }),
};

LoanPaymentMethod.defaultProps = {
  paymentMethod: null,
};

export default LoanPaymentMethod;
