import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MaterialIcon } from 'styles/Icons';
import { formatMoneyDecimalPlainFromCents, formatMaskedCreditCardNumberWithBullets } from 'lib/Formatters';
import { useTranslation } from 'react-i18next';
import { MiniPanel } from 'styles/PaymentMethodStyles';
import PanelHeader from 'components/PanelHeader';
import { NavLink } from 'react-router-dom';
import theme from 'styles/Theme';
import { Switch, SwitchCheckbox, SwitchSlider, FormCheck } from 'styles/CommonStyles';
import { closedLoansStatuses } from 'lib/constants/loanStatuses';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const MiniPanel2 = styled(MiniPanel)`
  background-color: ${(props) => props.theme.colors.upliftWhite};
  border: 1px solid ${(props) => (props.checked ? props.theme.colors.upliftTeal : props.theme.colors.borderGrey)};
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1rem;
  width: 100%;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.upliftTealActive};
    cursor: pointer;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 0.75rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledLoanIdLabel = styled.label`
  color: ${(props) => props.theme.colors.labelGrey};
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0;
  padding-right: 4px;
  text-transform: uppercase;
`;

const StyledCheckbox = styled(FormCheck)`
  input[type='checkbox'] {
    border: 2px solid ${(props) => (props.checked ? 'transparent' : props.theme.colors.borderBlack)};
    cursor: pointer;
    height: 24px;
    width: 24px;
    appearance: ${(props) => (props.checked ? '' : 'none')};
    border-radius: 2px;
  }
  input[type='checkbox']:active,
  input[type='checkbox']:checked {
    accent-color: ${(props) => props.theme.colors.upliftTeal};
  }

  input[type='checkbox']:disabled {
    border-color: ${(props) => (props.checked ? 'transparent' : props.theme.colors.borderGrey)};
    cursor: not-allowed;
  }
`;

const MerchantInfo = styled.h2`
  display: flex;
  flex-flow: row nowrap;
  width: 17.5rem;
  gap: 16px 4px;
`;

const MonthlyPayment = styled.div`
  color: ${(props) => props.theme.colors.iconGrey};
  font-size: 1rem;
  padding-right: 12px;
`;

const PaymentMethod = styled.div`
  align-items: center;
  border-left: 1px solid ${(props) => props.theme.colors.borderGrey};
  display: flex;
  font-size: 0.625rem;
  height: 20px;
  margin: 2px;
  padding-left: 12px;
`;

const StyledPaymentMethodText = styled.div`
  padding-left: 4px;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const MerchantAvatar = styled.img`
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.borderGrey};
  margin-bottom: 8px;
  height: 40px;
  width: 40px;
  vertical-align: middle;
  text-align: center;
  object-fit: contain;
`;

const StyledAutopayEnabled = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.upliftLightestTeal};
  border-radius: 32px;
  color: ${(props) => props.theme.colors.upliftTealText};
  cursor: default;
  display: flex;
  gap: 4px;
  padding: 4px 8px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'none')};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
`;

const iconSize = '2.4rem';
const iconSizeSmall = '1.3 rem';
const iconSizeXSmall = '1rem';

const LoanSummaryCard = ({ checked, disabled, loanDetails, onEnableAutoPay, onSelect, hasCheckbox, loanStatusCode }) => {
  const { i18n, t } = useTranslation();

  const glyph =
    loanDetails.merchantInfo && loanDetails.merchantInfo.logoUrl ? (
      <MerchantAvatar src={loanDetails.merchantInfo.logoUrl} alt={loanDetails.merchantInfo?.name} />
    ) : (
      <MaterialIcon name="person" size={iconSize} color={theme.colors.backgroundGrey} />
    );

  const paymentMethodText = loanDetails?.paymentMethod?.accountNumberMask || loanDetails?.paymentMethod?.cardNumberMask;
  const autopaySwitchId = `autopay${loanDetails?.loanId}`;
  const autopaySwitchAriaLabel = `Autopay ${loanDetails?.loanId}`;
  const isLoanPaidOff = loanStatusCode && closedLoansStatuses.includes(loanStatusCode.toUpperCase());

  return (
    <Container>
      {hasCheckbox && (
        <StyledCheckbox
          aria-label={loanDetails.loanId}
          aria-describedby={`loan-summary-${loanDetails.loanId}`}
          checked={checked}
          disabled={disabled}
          onChange={(event) => onSelect(event.currentTarget.value, event)}
          value={loanDetails.loanId}
          type="checkbox"
        />
      )}
      <MiniPanel2 showCard checked={checked} id={`loan-summary-${loanDetails.loanId}`}>
        <MerchantInfo>
          <PanelHeader
            labelLeft={t('account.payment_methods.manage_payment.purchased_from')}
            contentLeft={loanDetails?.merchantInfo.name}
            glyph={glyph}
            showHeaderRight={false}
          />
        </MerchantInfo>
        <StyledDiv column style={{ flexWrap: 'wrap', marginBottom: '12px' }}>
          <StyledDiv>
            <MonthlyPayment>
              {formatMoneyDecimalPlainFromCents(loanDetails?.monthlyPaymentAmount?.amount, true, i18n.language)}/
              {t('loans.details.month')}
            </MonthlyPayment>
            <PaymentMethod>
              {loanDetails?.paymentMethod ? (
                <>
                  <MaterialIcon
                    size={iconSizeSmall}
                    color={theme.colors.upliftTeal}
                    name={loanDetails.paymentMethod?.paymentMethodType === 'ach' ? 'account_balance' : 'credit_card'}
                  />
                  <StyledPaymentMethodText>{formatMaskedCreditCardNumberWithBullets(paymentMethodText)}</StyledPaymentMethodText>
                </>
              ) : (
                <>
                  <MaterialIcon size={iconSizeSmall} name="warning" color={theme.colors.warningIcon} />
                  <StyledPaymentMethodText>{t('account.payment_methods.manage_payment.no_payment_method')}</StyledPaymentMethodText>
                </>
              )}
            </PaymentMethod>
          </StyledDiv>
          <StyledDiv style={{ marginTop: '0.75rem' }}>
            <StyledLoanIdLabel>{t('account.payment_methods.manage_payment.loan_id')}</StyledLoanIdLabel>
            <StyledNavLink to={{ pathname: '/loans', hash: `#${loanDetails.loanId}` }}>
              {loanDetails?.loanId}
              <MaterialIcon size={iconSizeXSmall} name="open_in_new" style={{ marginLeft: '4px', verticalAlign: 'text-bottom' }} />
            </StyledNavLink>
          </StyledDiv>
        </StyledDiv>
        <StyledDiv>
          {loanDetails?.autopay ? (
            <StyledAutopayEnabled>
              <MaterialIcon name="autorenew" color={theme.colors.upliftTeal} size={iconSizeXSmall} />
              {t('account.payment_methods.manage_payment.autopay_enabled')}
            </StyledAutopayEnabled>
          ) : (
            <StyledDiv style={{ gap: '8px' }}>
              <Switch data-testid="autopay-button" htmlFor={autopaySwitchId}>
                <SwitchCheckbox
                  disabled={disabled || isLoanPaidOff}
                  onClick={(event) => onEnableAutoPay(event.currentTarget.value, event)}
                  type="checkbox"
                  value={loanDetails.loanId}
                  id={autopaySwitchId}
                  aria-label={autopaySwitchAriaLabel}
                />
                <SwitchSlider />
              </Switch>
              {t('account.payment_methods.manage_payment.enable_autopay')}
            </StyledDiv>
          )}
        </StyledDiv>
      </MiniPanel2>
    </Container>
  );
};

LoanSummaryCard.displayName = 'LoanSummaryCard';

LoanSummaryCard.defaultProps = {
  disabled: false,
  onSelect: null,
  hasCheckbox: true,
  loanStatusCode: null,
};

LoanSummaryCard.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onEnableAutoPay: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  hasCheckbox: PropTypes.bool,
  loanStatusCode: PropTypes.string,
  loanDetails: PropTypes.shape({
    paymentMethod: PropTypes.shape({
      accountNumberMask: PropTypes.string,
      cardNumberMask: PropTypes.string,
      paymentMethodId: PropTypes.string,
      paymentMethodType: PropTypes.oneOf(['ach', 'card']),
    }),
    autopay: PropTypes.bool.isRequired,
    loanId: PropTypes.string.isRequired,
    monthlyPaymentAmount: PropTypes.shape({
      amount: PropTypes.number,
    }),
    merchantInfo: PropTypes.shape({
      contact: PropTypes.objectOf(PropTypes.string),
      logoUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default LoanSummaryCard;
