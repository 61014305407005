/* eslint-disable import/prefer-default-export */
export const LOAN_STATUS_CODES = {
  CANCELLED: 'CANCELED',
  CANCELLED_UNWIND: 'CANCELED (UNWIND)',
  CANCELLED_REFUND: 'CANCELED (REFUND)',
  CHARGE_OFF: 'CHARGE OFF',
  FORGIVEN: 'FORGIVEN',
  GL_CHARGE_OFF: 'GL CHARGE OFF',
  NON_ACCRUAL: 'NON-ACCRUAL',
  PAID_OFF: 'PAID OFF',
  PAID_OFF_MERCHANT: 'FULLY PAID (MERCHANT)',
  SOLD_DEBT: 'SOLD DEBT',
  PENDING: 'PENDING',
};

export const HARDSHIP_CODES = {
  CLOSED: 'CLOSED',
  FINAL_PAYMENT: 'FINAL_PAYMENT',
  GOOD_STANDING: 'GOOD_STANDING',
  INELIGIBLE: 'INELIGIBLE',
  INELIGIBLE_SECONDARY_STATUS: 'INELIGIBLE_SECONDARY_STATUS',
  NO_BILLINGS: 'NO_BILLINGS',
  ONE_BILLING_PAST_DUE: 'ONE_BILLING_PAST_DUE',
  MAX_ENROLLMENTS: 'MAX_ENROLLMENTS',
};

export const closedLoansStatuses = [LOAN_STATUS_CODES.PAID_OFF, LOAN_STATUS_CODES.PAID_OFF_MERCHANT];
