import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTIONS } from 'state/actionTypes';
import { noOp } from 'lib/utils';
import { trackAll } from 'analytics/tracking';

import {
  postLoanAutoPayInitialize,
  postLoanAutoPayInitializeReset,
  postLoanAutoPayPersist,
  postLoanAutoPayPersistReset,
} from 'state/autoPay/actions';

import { autoPayInitializeSelector } from 'state/autoPay/selectors';

import { closedLoansStatuses } from 'lib/constants/loanStatuses';
import AutoPayModal from './AutoPayModal';
import AutoPayAuthorizationModal from './AutoPayAuthorizationModal';

/**
 * Renders the sequence of modals related to AutoPay.
 * Should only render this when the flow is to take place. Unmount it when the flow is done.
 */
const AutoPayFlow = ({
  loanId,
  loanStatusCode,
  merchantInfo,
  onAutoPayClose,
  onAutoPayConfirm,
  onAutoPayAuthorizeClose,
  onAutoPayAuthorizeConfirm,
  onError,
  paymentMethodId,
  skipToAuthorize,
}) => {
  const dispatch = useDispatch();
  const autoPayInitializeData = useSelector(autoPayInitializeSelector);

  const [showAutoPayModal, setShowAutoPayModal] = useState(!skipToAuthorize);
  const [showAutoPayAuthorizationModal, setShowAutoPayAuthorizationModal] = useState(skipToAuthorize);

  const closeAutoPayModal = useCallback(() => {
    dispatch(postLoanAutoPayInitializeReset());
    setShowAutoPayModal(false);
    onAutoPayClose();
  }, [dispatch, onAutoPayClose]);

  const closeAuthorizationModal = useCallback(() => {
    dispatch(postLoanAutoPayPersistReset());
    setShowAutoPayAuthorizationModal(false);
    onAutoPayAuthorizeClose();
  }, [dispatch, onAutoPayAuthorizeClose]);

  const initializeAutoPay = useCallback(async () => {
    const responseAction = await dispatch(postLoanAutoPayInitialize(loanId, paymentMethodId));
    if (responseAction.type === ACTIONS.postLoanAutoPayInitializeSuccess) {
      setShowAutoPayModal(false);
      setShowAutoPayAuthorizationModal(true);
      onAutoPayConfirm(responseAction.payload?.data);
    } else {
      onError();
    }
  }, [dispatch, loanId, onError, onAutoPayConfirm, paymentMethodId]);

  const persistAutoPay = useCallback(async () => {
    trackAll(
      {
        category: 'user',
        action: 'click',
        label: 'enable-auto-pay',
        value: 1,
      },
      {
        loanId,
      }
    );

    const responseAction = await dispatch(postLoanAutoPayPersist(loanId, autoPayInitializeData.sessionId));
    if (responseAction.type === ACTIONS.postLoanAutoPayPersistSuccess) {
      setShowAutoPayAuthorizationModal(false);
      onAutoPayAuthorizeConfirm(responseAction.payload?.data);
    } else {
      onError();
    }
  }, [autoPayInitializeData, dispatch, loanId, onAutoPayAuthorizeConfirm, onError]);

  if (closedLoansStatuses.includes(loanStatusCode)) {
    return null;
  }

  return (
    <>
      {showAutoPayModal && <AutoPayModal onClose={closeAutoPayModal} onConfirm={initializeAutoPay} />}

      {showAutoPayAuthorizationModal && (
        <AutoPayAuthorizationModal
          documentUrl={autoPayInitializeData?.url}
          loanId={loanId}
          merchantInfo={merchantInfo}
          onClose={closeAuthorizationModal}
          onConfirm={persistAutoPay}
        />
      )}
    </>
  );
};

AutoPayFlow.propTypes = {
  loanId: PropTypes.string.isRequired,
  merchantInfo: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  loanStatusCode: PropTypes.string,
  onAutoPayClose: PropTypes.func,
  onAutoPayConfirm: PropTypes.func,
  onAutoPayAuthorizeClose: PropTypes.func,
  onAutoPayAuthorizeConfirm: PropTypes.func,
  onError: PropTypes.func,
  paymentMethodId: PropTypes.string.isRequired,
  skipToAuthorize: PropTypes.bool,
};

AutoPayFlow.defaultProps = {
  merchantInfo: null,
  loanStatusCode: null,
  onAutoPayClose: noOp,
  onAutoPayConfirm: noOp,
  onAutoPayAuthorizeClose: noOp,
  onAutoPayAuthorizeConfirm: noOp,
  onError: noOp,
  skipToAuthorize: false,
};

export default AutoPayFlow;
