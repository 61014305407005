import React, { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { loansSelector, loansLoadingSelector } from 'state/loans/selectors';
import PropTypes from 'prop-types';
import { Alert, ButtonOutline, Button, Row } from 'styles/CommonStyles';
import moment from 'moment';

import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';
import { paymentMethodsSelector, paymentMethodPrefillLoansSelector } from 'state/paymentMethods/selectors';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { CCHeader } from 'styles/PaymentMethodStyles';
import { PaymentMethodSuccessAlert } from 'views/account/wallet/components/Alert';
import { orderBy } from 'lodash';
import { closedLoansStatuses } from 'lib/constants/loanStatuses';
import LoanSummaryCard from './LoanSummaryCard';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 376px;
  ${(props) => props.isModalView && 'max-height: 600px'};
`;

const StyledHeader = styled.div`
  display: flex;
  gap: 8px;
  ${(props) => props.isModalView && 'flex: none'};
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li + li {
    margin-top: 1.5rem;
  }
`;

const StyledAlertContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

const StyledAlert = styled(Alert)`
  display: flex;
  gap: 11px;
  margin-bottom: 0;
`;

const ButtonGroup = styled.div`
  text-align: center;
  flex-grow: 1;
  ${(props) => props.isModalView && 'display: flex'};
  ${(props) => props.isModalView && 'gap: 24px'};
`;

const StyledLoansListContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

const StyledRow = styled(Row)`
  ${(props) => props.isModalView && 'margin: 0'};
  ${(props) => props.isModalView && 'flex: none'};
`;

const StyledButton = styled(Button)`
  ${(props) => props.isModalView && 'width: 100%'};
`;

const StyledButtonOutline = styled(ButtonOutline)`
  ${(props) => !props.isModalView && 'margin-right: 24px'};
  ${(props) => props.isModalView && 'width: 100%'};
`;

export const getNextPaymentDate = (loan, paymentHistory, order) => {
  if (loan.loanStatusCode?.toUpperCase() === 'PENDING') {
    return moment(order?.hotelReservation?.checkIn).format('YYYY-MM-DD');
  }
  if (paymentHistory.find((x) => x.status === 'Overdue')?.date) {
    return moment(paymentHistory.find((x) => x.status === 'Overdue')?.date).format('YYYY-MM-DD');
  }
  if (paymentHistory.find((x) => x.status === 'Next Payment')?.date) {
    return moment(paymentHistory.find((x) => x.status === 'Next Payment')?.date).format('YYYY-MM-DD');
  }
  return null;
};

const LoanSummarySelection = memo((props) => {
  const { t } = useTranslation();
  const {
    loading,
    onSelectCard,
    onSubmit,
    onSkip,
    showAlertSuccess,
    setShowAlertSuccess,
    maskedAccountNumber,
    defaultPaymentMethodType,
    isModalView,
  } = props;
  const loans = useSelector(loansSelector);
  const loansLoading = useSelector(loansLoadingSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  const paymentMethodPreselectedLoanIds = useSelector(paymentMethodPrefillLoansSelector);

  const [selectedLoans, setSelectedLoans] = useState([]);
  const [autopayStatuses, setAutopayStatuses] = useState([]);
  const [replacePaymentMethodLoans, setReplacePaymentMethods] = useState([]);

  const activeLoans = useMemo(() => {
    const mappedLoans =
      loans &&
      loans
        .filter((loan) => loan.state?.toUpperCase() === 'ACTIVE' && !closedLoansStatuses?.includes(loan?.loanStatusCode))
        .map((loan) => {
          const { paymentToken, paymentHistory, order } = loan;
          let paymentMethodObject = null;
          if (paymentToken) {
            paymentMethodObject = paymentMethods.find((payment) => payment.paymentMethodId === paymentToken);
          }
          const nextPaymentDate = getNextPaymentDate(loan, paymentHistory, order);
          return {
            loanId: loan.loanId,
            hasPaymentMethod: !!paymentMethodObject,
            paymentMethod: paymentMethodObject,
            autopay: paymentMethodObject?.autopay,
            nextPaymentDate,
            ...loan,
          };
        });
    return orderBy(mappedLoans, ['hasPaymentMethod', 'nextPaymentDate']);
  }, [loans, paymentMethods]);

  useEffect(() => {
    setReplacePaymentMethods(
      selectedLoans.filter((selectedLoanId) => activeLoans.some((loan) => loan.loanId === selectedLoanId && loan?.paymentMethod))
    );
  }, [selectedLoans, activeLoans]);

  useEffect(() => {
    setSelectedLoans(paymentMethodPreselectedLoanIds);
  }, [paymentMethodPreselectedLoanIds]);

  const handleAutopayToggle = (value) => {
    if (autopayStatuses.includes(value)) {
      setAutopayStatuses(autopayStatuses.filter((loanId) => value !== loanId));
    } else {
      setAutopayStatuses((autopayStatus) => [...autopayStatus, value]);
    }
  };

  const handleSelect = (value) => {
    if (selectedLoans.includes(value)) {
      setSelectedLoans(selectedLoans.filter((loanId) => loanId !== value));
    } else {
      setSelectedLoans((selectedLoan) => [...selectedLoan, value]);
    }
    if (onSelectCard) {
      onSelectCard(value);
    }
  };

  const handleSkip = () => {
    setShowAlertSuccess(false);
    if (onSkip) {
      onSkip();
    }
  };

  const handleOnSubmit = () => {
    setShowAlertSuccess(false);

    const loansWithAutopayStatus = selectedLoans.map((selectedLoanId) => {
      return {
        loanId: selectedLoanId,
        autopayEnabled:
          autopayStatuses?.includes(selectedLoanId) || !!activeLoans.find((loan) => loan.loanId === selectedLoanId && loan.autopay),
      };
    });

    if (onSubmit) {
      onSubmit(loansWithAutopayStatus);
    }
  };

  return (
    <div>
      {loansLoading && <Loader style={{ margin: isModalView ? '2rem' : undefined }} />}
      {!loansLoading && (
        <>
          <PaymentMethodSuccessAlert
            defaultPaymentMethodType={defaultPaymentMethodType}
            showAlert={showAlertSuccess}
            setShowAlert={setShowAlertSuccess}
            maskedAccountNumber={maskedAccountNumber}
          />
          <StyledContainer isModalView={isModalView}>
            {!loansLoading && activeLoans?.length && (
              <>
                <StyledHeader isModalView={isModalView}>
                  {!isModalView && <MaterialIcon name="monetization_on" color={theme.colors.textBlack} size="24px" />}
                  <CCHeader style={{ paddingBottom: '0' }}>{t('account.payment_methods.manage_payment.manage_payments')}</CCHeader>
                </StyledHeader>
                {t('account.payment_methods.manage_payment.add_payment_method_to_loan')}
                <StyledLoansListContainer>
                  <StyledList>
                    {activeLoans.map((loan) => (
                      <li key={`${loan.loanId}`}>
                        <LoanSummaryCard
                          checked={selectedLoans?.includes(loan.loanId)}
                          disabled={loading}
                          onEnableAutoPay={(value, event) => handleAutopayToggle(value, event)}
                          onSelect={(value, event) => handleSelect(value, event)}
                          loanDetails={loan}
                          merchantInfo={loan.merchantInfo}
                          loanStatus={loan?.loanStatusCode}
                        />
                      </li>
                    ))}
                  </StyledList>
                </StyledLoansListContainer>
                {replacePaymentMethodLoans.length > 0 && (
                  <StyledAlertContainer>
                    <StyledAlert variant="info" data-testid="replace-alert">
                      <>
                        <MaterialIcon name="info" color={theme.colors.secondaryMediumBlue} size="24px" />
                        {t('account.payment_methods.manage_payment.replace_existing_payment_method')}&nbsp;
                      </>
                      <>
                        {replacePaymentMethodLoans
                          .map(
                            (loanId) =>
                              `${t('account.payment_methods.manage_payment.loan')}
                      ${loanId}`
                          )
                          .join(', ')}
                      </>
                    </StyledAlert>
                  </StyledAlertContainer>
                )}
              </>
            )}
            <StyledRow isModalView={isModalView}>
              {loading ? (
                <Loader style={{ width: '100%' }} />
              ) : (
                <ButtonGroup isModalView={isModalView}>
                  <StyledButtonOutline onClick={handleSkip} variant="outline-primary" isModalView={isModalView}>
                    {t('account.payment_methods.manage_payment.skip')}
                  </StyledButtonOutline>
                  <StyledButton onClick={handleOnSubmit} type="submit" isModalView={isModalView}>
                    {t('common.buttons.continue')}
                  </StyledButton>
                </ButtonGroup>
              )}
            </StyledRow>
          </StyledContainer>
        </>
      )}
    </div>
  );
});

LoanSummarySelection.propTypes = {
  defaultPaymentMethodType: PropTypes.string,
  loading: PropTypes.bool,
  maskedAccountNumber: PropTypes.string,
  onSelectCard: PropTypes.func,
  onSkip: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setShowAlertSuccess: PropTypes.func,
  showAlertSuccess: PropTypes.bool,
  isModalView: PropTypes.bool,
};

LoanSummarySelection.defaultProps = {
  defaultPaymentMethodType: null,
  loading: false,
  maskedAccountNumber: null,
  onSelectCard: () => {},
  setShowAlertSuccess: () => {},
  showAlertSuccess: true,
  isModalView: false,
};

export default LoanSummarySelection;
